import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Typography, Box } from "@material-ui/core";
import {
  requestVehicleNameById,
  requestDriverNameByPin,
  setVehicleId,
  loadDriverPin
} from "../../actions/actionDriverIdentification";

import DriverIdentification from "./DriverIdentification";
import PinInput from "./PinInput/PinInput";
import DriverIdentificationConditionalLink from "./DriverIdentificationConditionalLink";

import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { RootState } from "../../store";
import LoadingIndicator from "../common/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { customColors } from "../geofences/generic/StylingOptions";

const useStyles = makeStyles<Theme>((theme) => ({
  contentContainer: {
    textAlign: "center",
  },
  pageContainer: {
    padding: "10vh 50px 50px 50px",
    width: "100%",
    backgroundColor: customColors.ilogsBlueBright,
    color: "white",
    display: "flex",

    [theme.breakpoints.up("lg")]: {
      padding: "20vh 50px 50px 50px",
    },
  },
  enterPinText: {
    color: "white",
    marginTop: 20,
    "@media (max-width: 375px)": {
      fontSize: 20,
  },
  "@media (min-width: 390px)": {
      fontSize: 25, 
  },
  }
}));

type DriverIdentificationPinAssignmentProps = {
  assignModal: boolean,
  openAssignModal: () => void,
  closeAssignModal: () => void,
}

type Params = {
  vehicleId: string,
}

const DriverIdentificationPinAssignment: React.FC<DriverIdentificationPinAssignmentProps> = () => {
  const pinLength = 4;
  const [pin, setPin] = useState(Array(pinLength).fill(""));
  const { vehicleId } = useParams<Params>();
  const pathname = `driverIdentification/${vehicleId}`;
  const [currentIndex, setCurrentIndex] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { vehicleName, loading } = useSelector((state: RootState) => ({
    vehicleName: state.driverIdentification.vehicleName,
    loading: state.driverIdentification.loading
  }));

  useEffect(() => {
    !vehicleName && dispatch(requestVehicleNameById(vehicleId));

    dispatch(setVehicleId(vehicleId));
  }, []);


  useEffect(() => {
    if (pin[pinLength - 1]) {
      const concatenatedPin = pin.join("");
      dispatch(loadDriverPin());
      dispatch(requestDriverNameByPin({ vehicleId, pin: concatenatedPin }));
      history.push({pathname: `/${pathname}/tripType`, state: {pin: concatenatedPin}});
    }
  }, [pin]);


  return (   
      <DriverIdentification>
        {
          loading ? 
          <Box className={classes.pageContainer}>
            <LoadingIndicator color={"white"} />
          </Box> :
        <>
          <Grid item xs={10} className={classes.contentContainer}>
          {vehicleName && (
            <Typography variant="subtitle2" style={{ color: "white", fontSize: 15 }}>
             {t("driverIdentification.VehicleConfirmationMessage", {vehicleName: vehicleName})}
            </Typography>
          )}
           <Typography variant="subtitle2" className={classes.enterPinText}>
             {t("driverIdentification.addPin")}
            </Typography>
          </Grid>
          <Grid item xs={10} className={classes.contentContainer}>
            <PinInput length={pinLength} pin={pin} setPin={setPin} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
          </Grid>
          <Grid item xs={10} className={classes.contentContainer}>
            <DriverIdentificationConditionalLink pin={pin} pinLength={pinLength} pathname={pathname} />
          </Grid>
        </>
        }
       </DriverIdentification>
  );
};

export default DriverIdentificationPinAssignment;
