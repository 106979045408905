import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import {
  requestVehicleNameById,
} from "../../actions/actionDriverIdentification";

import DriverIdentification from "./DriverIdentification";
import DriverSearchBox from "./DriverSearchBox";
import { makeStyles } from "@material-ui/core";

import { Theme } from "@material-ui/core";
import { RootState } from "../../store";
import { UserResponseInfo } from "../../types/user/userResponse.types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles<Theme>((theme) => ({
  contentContainer: {
    textAlign: "center",
  },
  link: {
    color: "#00BCD4",
    marginTop: "5px",
    cursor: "pointer",
  },
  enterNameText: {
    color: "white",
    marginTop: 20,
    "@media (max-width: 375px)": {
      fontSize: 19,
    },
    "@media (min-width: 390px)": {
        fontSize: 25, 
    },
  }
}));

type DriverIdentificationForgotPinPageProps = {
  assignModal: boolean,
  openAssignModal: () => void,
  closeAssignModal: () => void,
}

const DriverIdentificationForgotPinPage: React.FC<DriverIdentificationForgotPinPageProps> = () => {
  const [selectedDriver, setSelectedDriver] = useState<UserResponseInfo | null>(null);
  const vehicleId = useSelector(
    (state: RootState) => state.driverIdentification.vehicleId
  );
  const pathname = `driverIdentification/${vehicleId}`;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const vehicleName = useSelector(
    (state: RootState) => state.driverIdentification.vehicleName
  );
  const vehicleDriver = useSelector(
    (state: RootState) => state.driverIdentification.vehicleDriver
  );

  const { t } = useTranslation();

  useEffect(() => {
    !vehicleName && dispatch(requestVehicleNameById(vehicleId));
  }, []);

  useEffect(() => {
    vehicleDriver && history.push(`/${pathname}/success`);
  }, [vehicleDriver]);

  const navigateBack = () => {
    history.push(`/${pathname}`);
  };

  return (
    <DriverIdentification>
      <Grid item xs={11} className={classes.contentContainer}>
        <Typography variant="subtitle2" style={{ color: "white", fontSize: 15 }}>
       {t("driverIdentification.vehicleAssignment",
          { vehicleName: vehicleName }
        )}
        </Typography>
        <Typography variant="subtitle2" className={classes.enterNameText}>
             {t("driverIdentification.typeName")}
            </Typography>
      </Grid>
      <Grid
        item
        xs={8}
        sm={5}
        md={4}
        lg={3}
        xl={2}
        className={classes.contentContainer}
      >
        <DriverSearchBox
          vehicleId={vehicleId}
          selectedDriver={selectedDriver}
          setSelectedDriver={setSelectedDriver}
          customFontSize={16}
        />
      </Grid>
      <Grid item xs={11} className={classes.contentContainer}>
        <Typography
          onClick={navigateBack}
          variant="subtitle2"
          component={"span"}
          className={classes.link}
        >
          {t("driverIdentification.mainPage")}
        </Typography>
      </Grid>
    </DriverIdentification>
  );
};

export default DriverIdentificationForgotPinPage;
