import { Grid, Box, Typography } from "@material-ui/core";

import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import logo from "../../assets/logo.png";

import { Theme } from '@mui/material/styles';
import React from "react";

import { RootState } from "../../store";
import { customColors } from "../geofences/generic/StylingOptions";


const useStyles = makeStyles<Theme>((theme) => ({
  pageContainer: {
    padding: "10vh 50px 50px 50px",
    width: "100%",
    minHeight: "90vh",
    backgroundColor: customColors.ilogsBlueBright,
    color: "white",

    [theme.breakpoints.up("lg")]: {
      padding: "20vh 50px 50px 50px",
    },
  },

  contentContainer: {
    textAlign: "center",
  },

  logoContainer: {
    textAlign: "center",
    marginRight: "20px",

    [theme.breakpoints.up("md")]: {
      marginRight: "0px",
    },
  },

  driveboxLogo: {
    height: "auto",
    maxWidth: "410px",
    minWidth: "50px",
    marginBottom: "5px",
    [theme.breakpoints.up("xs")]: {
      width: "68vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "40vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "30vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20vw",
    },
  },

  pageTitle: {
    fontWeight: 500,
    color: "#F0F0F0",
    "@media (max-width: 375px)": {
      fontSize: 40,
    },
    "@media (min-width: 390px)": {
        fontSize: 48, 
    },
  },

}));

type DriverIdentificationProps = {
  children: React.ReactNode,
  headerVisibility?: boolean
}

const DriverIdentification: React.FC<DriverIdentificationProps> = ({ children, headerVisibility=true }) => {
  const classes = useStyles();
  const error = useSelector((state: RootState) => state.driverIdentification.error);
  const vehicleId = useSelector((state: RootState) => state.driverIdentification.vehicleId);
  const pathname = `driverIdentification/${vehicleId}`;
  const location = useLocation();

  if (error && !location.pathname.includes("error")) {
    return <Redirect to={`/${pathname}/error`} />;
  }

  return (
    <Box className={classes.pageContainer}>
      <Grid container spacing={2} justify="center">
        {
          headerVisibility &&
          <>
            <Grid item xs={10} className={classes.logoContainer}>
              <img
                src={logo}
                alt="Logo"
                className={classes.driveboxLogo}
              />
            </Grid>
            <Grid item xs={10} className={classes.contentContainer}>
              <Typography className={classes.pageTitle}>
                QuickStart
              </Typography>
            </Grid>
          </>
        }
        {children}
      </Grid>
    </Box>
  );
};

export default DriverIdentification;
